<template>
	<div class="mainTem">

		<div class="tableConTop">
			<el-row>
				<el-col :span="5" class="tableConTopLeft">
					<h3>
						<el-button type="primary" round icon="el-icon-back" size="mini" @click="goBack()">{{$t('i18nn_ca5a01a5adf20fe7')}}</el-button>
						<span class="tct_tit">{{$t('i18nn_6e961a14e09d9784')}}</span>
					</h3>
				</el-col>
				<el-col :span="19" class="tableConTopRig">
					<!-- <span class="" v-if="!isSel"> -->
					<!-- <el-button v-if="!isSel" type="success" @click="openDioalog(null, $t('i18nn_c0246c55b9cac963'))" size="small"
						icon="el-icon-plus">{{ 'undefined' }}</el-button> -->
					<!-- </span> -->
					<el-button type="success" circle icon="el-icon-refresh" size="small" @click="initData()">
					</el-button>
				</el-col>
			</el-row>
		</div>

		<div class="filterCon" style="">
			<ul class="filterConList">
				<!-- <li>
					<span>{{$t('i18nn_b40debd910bb74f2')}}</span>
					<el-select size="small" filterable clearable v-model="filterData.wh_notice_show_type"
						:placeholder="$t('i18nn_2ad108ab2c560530')" style="width: 100px;" @change="initData()">
						<el-option v-for="item in selectOption.wh_notice_show_type" :key="item.code" :label="$Utils.i18nKeyText(item,'codeText')"
							:value="item.code">
							<div class="sel_option_s1" style="">
								<span class="sel_option_name">{{ $Utils.i18nKeyText(item,'codeText') }}</span>
								<span class="sel_option_code" style="">{{ item.code }}</span>
							</div>
						</el-option>
					</el-select>
				</li> -->
				<li>
					<span>{{$t('i18nn_2528332bfcac14b5')}}</span>
					<el-select size="small" filterable clearable v-model="filterData.wh_notice_msg_type"
						:placeholder="$t('i18nn_2ad108ab2c560530')" style="width: 100px;" @change="initData()">
						<el-option v-for="item in selectOption.wh_notice_msg_type" :key="item.code"
							:label="$Utils.i18nKeyText(item,'codeText')" :value="item.code">
							<div class="sel_option_s1" style="">
								<span class="sel_option_name">{{ $Utils.i18nKeyText(item,'codeText') }}</span>
								<span class="sel_option_code" style="">{{ item.code }}</span>
							</div>
						</el-option>
					</el-select>
				</li>
				<li>
					<span>{{ $t('i18nn_f353b1ebbdc25be4') }}</span>
					<el-input type="text" v-model="filterData.keyword" size="small" clearable
						@keyup.enter.native="initData()" maxlength="50" :placeholder="$t('i18nn_3ee4c9b76289e93a')"
						style="width: 180px;" />
				</li>
				<li>
					<el-button icon="el-icon-search" size="small" type="primary" @click="initData()">
						{{ $t('i18nn_1e7246dd6ccc5539') }}
					</el-button>
				</li>
			</ul>
		</div>

		<div class="" v-loading="loading_load" :element-loading-text="$t('tips.loading')">
			<el-table id="out-table2" ref="multipleTable" :data="tableData" stripe :border="true"
				:height="$store.state.tableMaxHeight2" style="width: 100%" size="small">
				<el-table-column type="index" :index="$Utils.tableIndex(pagination)" fixed="left" width="50"
					align="center" :label="$t('Storage.tableColumn.no')"></el-table-column>

				<el-table-column prop="state" :label="$t('i18nn_6cdece641436d7ab')">
					<template slot-scope="scope">
						<el-tag v-if="'10'===scope.row.status" type="primary">{{scope.row.statusName}}</el-tag>
						<el-tag v-else-if="'20'===scope.row.status" type="danger">{{scope.row.statusName}}</el-tag>
						<el-tag v-else type="info">{{scope.row.statusName}}</el-tag>
					</template>
				</el-table-column>

				<!-- <el-table-column prop="showClientName" :label="$t('i18nn_b40debd910bb74f2')"></el-table-column> -->
				<el-table-column prop="msgTypeName" :label="$t('i18nn_2528332bfcac14b5')">
					<template slot-scope="scope">
						<el-tag v-if="'10'===scope.row.msgType" type="success">{{scope.row.msgTypeName}}</el-tag>
						<el-tag v-else-if="'20'===scope.row.msgType" type="warning">{{scope.row.msgTypeName}}</el-tag>
						<el-tag v-else type="info">{{scope.row.msgTypeName}}</el-tag>
					</template>
				</el-table-column>
				<el-table-column prop="msgText" :label="$t('i18nn_db1f861ffd10f028')">
					<template slot-scope="scope">
						<el-link type="primary" @click="toDet($event,scope.row)">
							{{ scope.row.msgText }}
						</el-link>
					</template>
				</el-table-column>
				<el-table-column prop="noticeDate" :label="$t('i18nn_43a3586339251494')"></el-table-column>
				<!-- <el-table-column prop="context" :label="$t('i18nn_bd0db0ad240cc83b')">
					<template slot-scope="scope">
						<el-button size="small" type="primary" @click="openMoreText($event, scope.row)">{{$t('i18nn_377fa66b0b28ce31')}}</el-button>
					</template>
				</el-table-column> -->
				<!-- <el-table-column prop="msgTextKey" :label="'阿里云Key'"></el-table-column> -->

				<!-- <el-table-column prop="remark" :label="$t('i18nn_15b3627faddccb1d')">
					<template slot-scope="scope">
						<el-popover placement="top" trigger="hover" ><div class="pre-text">{{ scope.row.remark }}</div>
							<span class="over_ellipsis" slot="reference">{{scope.row.remark}}</span>
						</el-popover>
					</template>
				</el-table-column> -->

				<!-- <el-table-column prop="attachments" label="undefined">
					<template slot-scope="scope">
						<div @click="openUploadViewFile($event, scope.row)">
							<div v-if="scope.row.attachments">
								<el-link type="primary">
									...
									<span>{{ $t('i18nn_73bbf51487ec69e9') }}</span>
									<span>{{ scope.row.attachments.length }}</span>
									<span>{{ $t('i18nn_7f18cb0ceb569eb0') }}</span>
								</el-link>
							</div>
						</div>
					</template>
				</el-table-column> -->

				<!-- <el-table-column prop="createTime" :label="$t('i18nn_726c51702f70c486')"></el-table-column> -->
				<!-- <el-table-column prop="updateTime" :label="$t('i18nn_fdc34fd7121f9c48')"></el-table-column> -->

			</el-table>

			<div class="tableCon_pagination">
				<hy-page-pagination :pagination="pagination" v-on:updatePageData="getPageData"></hy-page-pagination>
			</div>
		</div>

		<!--  内容查看 -->
		<!-- <dialogNoticeMoreTextView :openTime="moreTextOpenTime" :id="moreTextId"></dialogNoticeMoreTextView> -->

		<!--  附件查看 -->
		<!-- <whFileView :openTime="FileUploadViewOpenTime" :fileList="fileDataList"></whFileView> -->

	</div>
</template>
<script>
	import {
		clearObjectVal
	} from '@/utils/utils.js';
import {
		getDicData
	} from '@/axios/common.js';
	// import dialogFileUpload from '@/components/WarehouseCenter2/components/dialogFileUpload.vue';

	// import whFileView from '@/components/WarehouseCenter2/components/whFileView.vue';
	// import dialogNoticeMoreTextView from '@/components/WarehouseCenter2/NoticeMana/dialogNoticeMoreTextView.vue';

	export default {
		components: {
			// dialogFileUpload,
			// whFileView,
			// dialogNoticeMoreTextView
		},
		// props: {
		// 	// mobile:"",
		// 	isSel: {
		// 		default: function() {
		// 			return false
		// 		},
		// 		type: Boolean
		// 	},
		// },
		data() {
			return {
				// 	dialogFormVisible: false,
				// 	dialogFormStatus: 0, //0-新增，1-修改
				// 	dialogFormMsg: '',
				// dialogSelVisible: false,
				// FileUploadOpenTime: '',

				// FileUploadViewOpenTime: '',
				// fileDataList: [],

				// moreTextOpenTime: '',
				// moreTextId: '',

				// CateValue: [],
				loading: false,
				// form: {
				// 	"id": null, //"数据ID",
				// 	"showClient": null, //this.$t('i18nn_b40debd910bb74f2'),
				// 	"msgType": null, //this.$t('i18nn_2528332bfcac14b5'),
				// 	"msgText": null, //this.$t('i18nn_db1f861ffd10f028'),
				// 	"noticeDate": null, //this.$t('i18nn_43a3586339251494'),
				// 	"context": null, //this.$t('i18nn_bd0db0ad240cc83b'),
				// 	"msgTextKey": null, //"阿里云Key",
				// 	"attachments": [
				// 		// {
				// 		// 	"": null, //this.$t('i18nn_f5d43732e3f6cf4d')
				// 		// },
				// 	]
				// },

				// formRules: {
				// 	showClient: [{
				// 		required: true,
				// 		message: this.$t('FormMsg.Please_select'),
				// 		trigger: 'change'
				// 	}],
				// 	msgType: [{
				// 		required: true,
				// 		message: this.$t('FormMsg.Please_select'),
				// 		trigger: 'change'
				// 	}],
				// 	msgText: [{
				// 		required: true,
				// 		message: this.$t('FormMsg.Please_Enter'),
				// 		trigger: 'blur'
				// 	}],

				// },
				loading_load: false,
				tableData: [],
				//分页数据
				pagination: this.$Utils.defaultPagination(),
				selectOption: {
					// wh_no: []
					wh_notice_msg_type: [],
					wh_notice_show_type: [],
				},
				//查询，排序方式
				filterData: {
					orderBy: 'id_', //排序字段
					sortAsc: 'desc', //desc降序，asc升序
					// userName: '',
					wh_notice_msg_type: '',
					wh_notice_show_type: '',
					keyword: ''
				}
			};
		},
		activated() {
			// this.initData();
		},
		//创建时
		created() {
			// this.getPageData();
		},
		//编译挂载前
		mounted() {
			// this.getDicData();
			//数据字典
			getDicData(['wh_notice_msg_type', 'wh_notice_show_type'],
				(data)=>{
					this.selectOption.wh_notice_msg_type = data['wh_notice_msg_type'];
					this.selectOption.wh_notice_show_type = data['wh_notice_show_type'];
			});
			this.initData();
		},
		methods: {
			initData() {
				this.pagination.current_page = 1;
				// this.currentSel = {};
				// this.multipleSelection = [];
				this.getPageData();
				// this.getDicData();
				// this.getWhNoListData();
				this.$nextTick(() => {
					this.$refs.multipleTable.doLayout();
				});
			},
			goBack() {
				// console.log('window.history.length',window.history.length);
				// if (window.history.length > 2) {
				this.$router.go(-1);
				// } else {
				// 	this.$router.push({
				// 		name: 'WhDropShippingDashboard'
				// 	});
				// }
			},
			toDet(event, row) {
				event.stopPropagation();
				this.$router.push({name:"NoticeDet",query:{id:row.id}})
			},
			
			//查询数据
			serPageData() {
				this.pagination.current_page = 1;
				this.getPageData();
			},

			//请求分页数据
			getPageData() {
				// let _this = this;
				this.loading_load = true;

				this.$http
					.put(this.$urlConfig.WhNoticeList, {
						// sortAsc: this.filterData.sortAsc,
						// orderBy: this.filterData.orderBy,
						offset: (this.pagination.current_page - 1) * this.pagination.page_size, //当前页
						limit: this.pagination.page_size, //当前页显示数目

						// "status": "10",

						// showClient: this.filterData.wh_notice_show_type ? this.filterData.wh_notice_show_type : null,
						msgType: this.filterData.wh_notice_msg_type ? this.filterData.wh_notice_msg_type : null,
						// opModel: this.filterData.wh_op_type ? this.filterData.wh_op_type : null,
						keyword: this.filterData.keyword ? this.filterData.keyword : null
						// goodsSku: this.filterData.goodsSku ? this.filterData.goodsSku : null
						// goodsName: this.filterData.goodsName ? this.filterData.goodsName : null,
					})
					.then(({
						data
					}) => {
						// console.log(this.$store.getters.getUserInfo);
						console.log('分页，请求成功');
						console.log(data);
						
						this.loading_load = false;
						//表格显示数据
						this.tableData = data.rows;
						//当前数据总条数
						this.pagination.total = parseInt(data.total);
						//当前页数
						// this.pagination.current_page = parseInt(data.current);
						//当前页条数
						// this.pagination.page_size = parseInt(data.size);
					})
					.catch(error => {
						console.log(error);
						console.log('分页，请求失败');
						this.loading_load = false;
					});
			},

			//选择数据后回调
			// selRow(event, row) {
			// 	event.stopPropagation();
			// 	this.$emit('selectRow', row);
			// },

			//查询数据字典
			// getDicData() {
			// 	this.$http
			// 		.put(this.$urlConfig.HyDicQueryList, ['wh_notice_msg_type', 'wh_notice_show_type'])
			// 		.then(({
			// 			data
			// 		}) => {
			// 			console.log('查询数据字典，请求成功');
			// 			console.log(data);
			// 			if (200 == data.code && data.data) {
			// 				this.selectOption.wh_notice_msg_type = data.data['wh_notice_msg_type'];
			// 				this.selectOption.wh_notice_show_type = data.data['wh_notice_show_type'];

			// 			} else {
			// 				if (!data.msg) {
			// 					data.msg = this.$t('tips.submitError');
			// 				}
			// 				this.$message.warning(data.msg);
			// 			}
			// 		})
			// 		.catch(error => {
			// 			console.log(error);
			// 			console.log('查询数据字典接口，请求失败');
			// 			this.$message.error(this.$t('tips.submitRequestError'));
			// 		});
			// }
		}
	};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
	// @import url(@/assets/css/client_module.less);
</style>
